import React from "react";
import { Link } from "gatsby";
import Layout from "../Components/Layout";
import { ArrowAtgal } from "../Components/Buttons";

function Rules() {
  return (
    <Layout>
    <div className="Rules">
      <div className="rulesNav">
        <Link to="/" aria-label="Grįžti atgal į pagrindinį puslapį">
          <ArrowAtgal>GRĮŽTI Į PAGRINDINĮ PUSLAPĮ</ArrowAtgal>
        </Link>
      </div>
      <div className="rulesMain">
        <h3>TAISYKLĖS</h3>
        
          <p className="questions">
            Apie „Europos egzaminą“
          </p>
          <p id="body">
            „Europos egzaminas“ – tai žinių apie ES, jos veikimą ir aktualijas konkursas, skirtas skatinti visuomenės domėjimąsi Europos Sąjunga ir paminėti Europos dieną.
            <br />
            <br />
            Šiais metais egzamine išskirtinis dėmesys skiriamas Europos žaliajai ir skaitmeninei pertvarkai, Europos Sąjungos solidarumui su Ukraina, Europos įgūdžių metams ir bendrajai rinkai.
          </p>
          <p className="questions">
            Kaip organizuojamas „Europos egzaminas“?
          </p>
          <p id="body">
            „Europos egzaminas“ vyks portale https://europosegzaminas.lrt.lt/. Egzamino dieną (prieš pradedant spręsti užduotis) dalyviai turės nurodyti savo vardą ir pavardę bei el. pašto adresą.
            <br />
            <br />
            Mokiniai turės pažymėti savo atstovaujamą mokyklą.
            <br />
            <br />
            Spręsdami egzaminą gegužės 9 d. 08:00-23:59 val. dalyviai turės atsakyti į 10 klausimų su pasirenkamais atsakymų variantais apie Europos Sąjungą.      
          </p>
          <p className="questions">
            Kas gali dalyvauti „Europos egzamine“?
            </p>
          <p id="body">
            „Europos egzamine“ gali dalyvauti visi norintys. Dalyviai spręs užduotis mokinių ir individualių dalyvių kategorijose. „Europos egzamino“ užduotis galima spręsti vieną kartą.
            <br />
            <br />
            Būkite atidūs: paspaudę „Pradėti egzaminą“, naršyklėje nespauskite grįžti „Atgal“ („Back“) ar „Atnaujinti“ („Refresh“), nes dar kartą pradėti egzamino nebus galimybės.
            <br />
            <br />
            Organizatoriai pasilieka teisę bet kuriuo metu stabdyti konkursą ar šalinti konkurso dalyvį, kuris elgiasi galimai nesąžiningai.
            <br />
            <br />
            Konkurso organizatoriai iš konkurso šalins dalyvius, kurie registruosis su laikino el. pašto adresais (tokiais kaip mailinator ir pan. alternatyvomis).
            <br />
            <br />
            Jei organizatoriai per 24 valandas registracijoje nurodytais dalyvių kontaktais (el. paštu) susisiekti negali, organizatoriai turi teisę tokius dalyvius šalinti iš konkurso.
            <br />
            <br />
            Jei turite klausimų, kreipkitės el. paštu <a href="mailto:europosegzaminas@lrt.lt">europosegzaminas@lrt.lt</a>
          </p>
        
      </div>
    </div>
    </Layout>
  );
}

export default Rules;
